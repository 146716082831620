<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.5" y="8.5" width="19" height="13" rx="2.5" stroke="black" />
      <rect x="6" y="2" width="12" height="1" rx="0.5" fill="black" />
      <rect x="4" y="5" width="16" height="1" rx="0.5" fill="black" />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "SlideListIcon",
};
</script>
