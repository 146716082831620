<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11ZM7 10C5.34315 10 4 11.3431 4 13V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V13C20 11.3431 18.6569 10 17 10H7Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 10V6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6V10H15ZM12 2C9.79086 2 8 3.79086 8 6V11H16V6C16 3.79086 14.2091 2 12 2Z"
        fill="black"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "LockIcon",
};
</script>
